/* globals: booking_form_params */

/**
 * Returns the hour offset between the client and the server.
 *
 * @param {*} referenceDate at which to compute offset.
 * @return {number} Number of hours between server and client.
 */
export function get_client_server_timezone_offset_hrs( referenceDate ) {
	if ( ! booking_form_params.timezone_conversion ) {
		return 0;
	}

	let reference_time = moment( referenceDate );
	const client_offset = reference_time.utcOffset();
	reference_time.tz( booking_form_params.server_timezone );
	const server_offset = reference_time.utcOffset();

	return ( client_offset - server_offset ) / 60.0;
}
